import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UISettings } from 'src/app/ht-client-core/common/models/ui-setttings';
import { ApiService } from 'src/app/ht-client-core/common/services/api/api.service';
import { AuthenticationService } from 'src/app/ht-client-core/common/services/authentication/authentication.service';
@Injectable({
  providedIn: 'root',
})
export class EditorService {
  private colorUpdate = new Subject<any>();
  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService
  ) {}

  uploadNewSettings(settings: UISettings) {
    console.log('SETTINGS', settings);
    const exportSettings = this.filterSettings({ ...settings });
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'json');
    return this.apiService.post(
      'hotels/' + this.authService.getHotelId() + '/guestAppSettings',
      {
        config: exportSettings,
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.authService.getToken(),
          'Content-Type': 'application/json',
          responseType: 'json',
        },
      }
    );
  }

  filterSettings(settings) {
    settings.services.quickActionList.forEach((item) => {
      if (item.action) {
        delete item.action;
      }
    });
    return settings;
  }
}
