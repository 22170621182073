import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/operators';
import { CacheResolverService } from '../services/editor-cache/editor-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheResolver: CacheResolverService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.isCachable(req)) {
      const cachedResponse = this.cacheResolver.get(req.url);
      return cachedResponse
        ? of(cachedResponse)
        : this.sendNewRequest(req, next);
    } else {
      return this.sendNewRequest(req, next);
    }
  }
  isCachable(req) {
    return req.method === 'GET' ? true : false;
  }
  sendNewRequest(req: HttpRequest<any>, next: HttpHandler) {
    return next
      .handle(req)
      .pipe(tap((event) => this.cacheResolver.set(req.url, event, new Date())));
  }
}
