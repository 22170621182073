import { Location, registerLocaleData } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localeEl from '@angular/common/locales/el';
import { UiSetupService } from './ht-client-core/common/services/ui-setup/ui-setup.service';
import { AuthenticationService } from './ht-client-core/common/services/authentication/authentication.service';
import { ApiService } from './ht-client-core/common/services/api/api.service';
import { HotelService } from './ht-client-core/common/services/hotel/hotel.service';
import { LanguageService } from './ht-client-core/common/services/language/language.service';
import { EditorService } from './services/editor/editor.service';
import { UtilsService } from './ht-client-core/common/services/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('guestapp') guestapp: any;
  urlParams;
  user;
  previewUrl;

  //hotelId=7epQQ3IbIdCZCpwxxe9RpZifVf2gZrgOHOQKTReR  //Hotel Toolbox Resort
  //hotelId=LlT3VWQMTKow1dYLzOmWnPk1a8F6w72bqjfdoaXy  //Hotel Toolbox Suites

  constructor(
    private uiSetupService: UiSetupService,
    private location: Location,
    private authService: AuthenticationService,
    private api: ApiService,
    private translate: TranslateService,
    private hotelService: HotelService,
    private langService: LanguageService,
    private util: UtilsService
  ) {}

  ngOnInit(): void {
    this.initTranslate();
    this.initializeURLParams();
    this.uiSetupService.getUIUpdateListener().subscribe((uisettings) => {
      if (uisettings) {
        if (uisettings.cssSettings) {
          const darkMode = document.body.classList.contains('dark');
          setTimeout(() => {
            const primaryColor = darkMode
              ? uisettings.cssSettings.darkTheme.primary
              : uisettings.cssSettings.lightTheme.primary;

            this.guestapp.el.style.setProperty(
              '--ion-color-primary',
              primaryColor
            );
            this.guestapp.el.style.setProperty(
              '--ion-color-primary-rgb',
              this.util.hexToRgb(primaryColor)
            );
            this.guestapp.el.style.setProperty(
              '--ion-color-primary-shade',
              this.util.mixColor('#000000', primaryColor, 12)
            );
            this.guestapp.el.style.setProperty(
              '--ion-color-primary-tint',
              this.util.mixColor('#ffffff', primaryColor, 10)
            );
          }, 300);
        }
      }
    });
  }

  initTranslate() {
    registerLocaleData(localeEl, 'el');

    this.translate.setDefaultLang('el');
  }

  loadUI() {
    this.uiSetupService.loadUISettings().then((settings) => {
      const uis = settings;
      console.log('loaded ui settings', uis);
    });
  }
  async initializeURLParams() {
    const path = this.location.path(true);
    const hasParams = /\?(.+?\=.+){1}/;
    let params;

    if (hasParams.test(path)) {
      params = {};
      path
        .split('?')[1]
        .split('&')
        .forEach((both) => {
          const e = both.split('=');
          params[e[0]] = e[1];
        });
    }

    this.urlParams = params;
    // await this.authService.loadToken();
    // const token = this.authService.getToken();
    // if (!!token) {
    //   this.user = this.authService.getUser();
    // } else {
    //   Storage.clear();
    // }
    console.log('PARAMETERS', this.urlParams);
    if (this.urlParams !== undefined) {
      if (this.urlParams['apiKey']) {
        console.log('setting api key');
        this.api.setApiKey(this.urlParams['apiKey']);
        if (this.urlParams['hotelId']) {
          console.log('setting hotelId');
          this.authService.setHotelId(this.urlParams['hotelId']);
          this.uiSetupService.clearUISettings().then((_) => {
            this.loadUI();
          });
        }
        if (this.urlParams['token']) {
          console.log('this is the token', this.urlParams.token);
          this.authService.setToken(this.urlParams.token);
        }
        //  if (this.urlParams['code']) {
        //     this.authService.logout();
        //     this.router.navigateByUrl('/login', {
        //       state: { code: this.urlParams['code'] },
        //     });
        //   } else {
        //     this.router.navigateByUrl('/introduction');
        //   }
      }
    }
  }
}
