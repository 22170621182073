// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//HT TEST HOTELS: http://localhost:8200/setup/47200a634f6600d6403d025f4dacc077505c8477/ht1-demo
//HT TEST AS BASE URL PARAMS: http://localhost:8200?apiKey=47200a634f6600d6403d025f4dacc077505c8477&code=ht1-demo

//MINOS HOTEL: http://localhost:8200/setup/60317e701266d5fcd8bfb9d6bc3ea9759bfc1c5a/mhr-demo
export const environment = {
  production: false,
  //dev
  apikey: '47200a634f6600d6403d025f4dacc077505c8477', //client api key DEMO HOTEL
  apiEndpoint: 'https://www.hoteltoolbox.gr/api',
  // hotelId: "9vAVu3zO9I2feZc64jCkqanaVZ1OkqE1OMrvYRRK", //DEMOHOTEL DEV
  urlAddin: 'hotels',
  pushSenderId: '368771443744',
  pushAppId: 'd89b765e-23da-40bb-b894-b46631bb3055',
  showHotelList: true,
  encKey: '',
  weather: {
    app_id: 'bdefca156b8feeb96c0b9e76a0ec1324',
    endpoint: 'https://api.openweathermap.org/data/2.5',
    target_area: 'Agía Galíni, GR',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyC-LeXSqv7KM5k63O84UsvFADjxeeLtw4Y',
    authDomain: 'htguestapp.firebaseapp.com',
    projectId: 'htguestapp',
    storageBucket: 'htguestapp.appspot.com',
    messagingSenderId: '687311250434',
    appId: '1:687311250434:web:12ccb154e4aa19fd8e331b',
    measurementId: 'G-ZE1SDK92ST',
    databaseURL: '',
  },
  languages: ['en', 'el', 'de'],
  appName: 'HT Paradise',
  appUISettings: {
    version: 1,
    dashboard: {
      show: true,
      components: [
        {
          type: 'promotions',
        },
        {
          type: 'quick-feedback',
        },
      ],
    },
    hotelPage: {
      show: true,
      view: 'TILES', // options 'CARDS', 'TILES'
      exclude: [],
    },
    activities: {
      show: true,
    },
    services: {
      show: true,
      view: 'PQC',
      orders: true,
      concierge: true,
      promotions: true,
      reservations: true,
      quickActionList: [],
    },
    help: {
      show: true,
      maintenance: false,
      useful_numbers: false,
      contact: false,
    },
  },
  contact: {
    whatsapp: '+306986560721',
  },
  social: {
    instagram: 'INSTAGRAM_HASHTAG',
    facebook: 'FACEBOOK_FOLLOW',
    tripadvisor: 'TRIPADVISOR_RATE',
    youtube: 'SUBSCRIBE_ON_YOUTUBE',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
