import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from "@capacitor/device";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import "@capacitor-community/firebase-analytics";

@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(private router: Router) {}

  async initFb() {
    if ((await Device.getInfo()).platform == "web") {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
      this.router.events
        .pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
        .subscribe((e: RouterEvent) => {
          // console.log("route changed: ", e.url);
          this.setScreenName(e.url);
        });
    }
  }

  setUser(userId) {
    // Use Firebase Auth uid
    // const enc = CryptoJS.AES.encrypt(userId, environment.encKey);
    // FirebaseAnalytics.setUserId({
    //   userId: enc
    // });
  }

  setHotel(value) {
    this.setProperty("hotelId", value);
  }

  setProperty(name, value) {
    FirebaseAnalytics.setUserProperty({
      name: name,
      value: value
    });
  }

  logEvent() {
    FirebaseAnalytics.logEvent({
      name: "login",
      params: {
        method: "email"
      }
    });
  }

  setScreenName(screenName) {
    FirebaseAnalytics.setScreenName({
      screenName: screenName,
      nameOverride: screenName
    });
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled
    });
  }

  enable() {
    return FirebaseAnalytics.enable();
  }

  disable() {
    return FirebaseAnalytics.disable();
  }
}
