import { Injectable } from "@angular/core";
import { Storage } from "@capacitor/storage";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root"
})
export class LanguageService {
  constructor() {}

  getLang() {
    return Storage.get({ key: "lang" });
  }

  setLang(lang) {
    return Storage.set({ key: "lang", value: lang });
  }

  getAvailLangs() {
    return new Promise<string[]>((resolve) => {
      Storage.get({ key: "availableLang" }).then((languages) => {
        if (languages.value) {
          resolve(languages.value.split(","));
        } else {
          resolve(environment.languages);
        }
      });
    });
  }

  setAvailLangs(langs) {
    return Storage.set({ key: "availableLang", value: langs });
  }

  async resetLangs() {
    await Storage.remove({ key: "availableLangs" });
  }
}
