import { Injectable } from '@angular/core';
@Injectable()
export class CacheResolverService {
  cache = new Map();
  expiry = 15000;
  get(url: string) {
    const result = this.cache.get(url);
    if (result) {
      const response = result.response;
      const setDate = result.date;
      return Date.now() - setDate.getTime() > 15000
        ? this.delete(url)
        : response;
    } else {
      return null;
    }
  }
  set(url: string, response, date) {
    this.cache.set(url, { response, date });
  }
  delete(url: string) {
    this.cache.delete(url);
    return null;
  }
}
