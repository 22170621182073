import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Page } from "../../models/page";
import { ApiService } from "../api/api.service";
import { Hotel } from "../../models/hotel";
import { AuthenticationService } from "../authentication/authentication.service";
import { Space } from "../../models/space";
import { LanguageService } from "../language/language.service";
@Injectable({
  providedIn: "root"
})
export class HotelService {
  hotel: Hotel;
  private hotelUpdated = new Subject<any>();
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private languageService: LanguageService
  ) {}

  getHotelUpdateListener() {
    return this.hotelUpdated.asObservable();
  }

  getHotel(cache: boolean = true) {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus"
        },
        null,
        cache
      )
      .pipe(
        map((res) => {
          // console.log(res);
          res["data"].pages.forEach((page) => {
            this.archivePage(page);
          });
          return res["data"];
        })
      )
      .subscribe((hotel: Hotel) => {
        this.hotel = hotel;
        this.hotelUpdated.next({ ...this.hotel });
      });
  }

  setHotel(hotel) {
    this.hotel = hotel;
    this.hotelUpdated.next({ ...this.hotel });
  }
  archivePage(page) {
    // console.log(page.image);

    if (page.children) {
      page.children.forEach((element) => {
        // console.log(element.image);
        this.archivePage(element);
      });
    }
  }

  getHotels(): Observable<Hotel[]> {
    return this.api
      .get(
        "guests/hotels",
        {
          // include: 'pages,pages.weekSchedule,pages.reservationForm,pages.space,pages.service'
        },
        null,
        false
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  getPages(): Observable<Page[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include: "pages,pages.weekSchedule,pages.reservationForm,pages.space"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"].pages["data"];
        })
      );
  }

  getSpaces(): Observable<Space[]> {
    return this.api
      .get(
        "hotels/" + this.authService.getHotelId() + "/spaces",
        {
          include: "page,page.weekSchedule,page.reservationForm"
        },
        null,
        true
      )
      .pipe(
        map((res) => {
          return res["data"];
        })
      );
  }

  async base64FromPath(path: string): Promise<string> {
    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };
      reader.readAsDataURL(blob);
    });
  }
}
