import { Injectable } from "@angular/core";
import { Storage } from "@capacitor/storage";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../api/api.service";
import { AuthenticationService } from "../authentication/authentication.service";
import { HotelService } from "../hotel/hotel.service";
import { LanguageService } from "../language/language.service";
const UI_SETTINGS = "appUISettings";

@Injectable({
  providedIn: "root"
})
export class UiSetupService {
  application;
  ui_settings;
  private uiUpdated = new Subject<any>();
  private appUpdated = new Subject<any>();

  constructor(
    private hotelService: HotelService,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.getStoredApplication().then((app) => {
      if (app) {
        this.application = app.value;
        this.appUpdated.next({ ...this.application });
      }
    });
    Storage.get({ key: UI_SETTINGS }).then((old_ui_config) => {
      if (old_ui_config) {
        const old_config = JSON.parse(old_ui_config.value);
        this.ui_settings = old_config;
        console.log("triggered the ui-settings", this.ui_settings);
        this.uiUpdated.next({ ...this.ui_settings });
      }
    });
  }

  getUIUpdateListener() {
    return this.uiUpdated.asObservable();
  }
  getAppUpdateListener() {
    return this.appUpdated.asObservable();
  }

  updateUISettings(ui_settings_json) {
    this.ui_settings = ui_settings_json;
    this.uiUpdated.next({ ...this.ui_settings });
  }

  async loadUISettings() {
    const saved_ui_settings = await Storage.get({ key: UI_SETTINGS });
    let reset = false;
    const newKey = (await Storage.get({ key: "slugApiKey" })).value;
    if (!!newKey) {
      if (this.apiService.getApiKey() !== newKey) {
        reset = true;
        this.apiService.setApiKey(newKey);
      }
    }
    await this.apiService
      .get(
        "hotels/" + this.authService.getHotelId(),
        {
          include:
            "pages,pages.weekSchedule,pages.reservationForm,pages.service,pages.menus,pages.tags,pages.children.menus"
        },
        null,
        false
      )
      .subscribe((hotel) => {
        const htApp = hotel.data;

        this.hotelService.setHotel(htApp);
        // console.log("THIS HOTEL", htApp);
        if (saved_ui_settings.value) {
          const ui_settings_json = saved_ui_settings
            ? JSON.parse(saved_ui_settings.value)
            : null;
          this.ui_settings = ui_settings_json;
          this.uiUpdated.next({ ...this.ui_settings });
          if (!!htApp.app_ui_settings) {
            if (
              ui_settings_json === null ||
              ui_settings_json.version < htApp.app_ui_settings.version ||
              reset
            ) {
              Storage.set({
                key: UI_SETTINGS,
                value: JSON.stringify(htApp.app_ui_settings)
              }).then((_) => {
                this.ui_settings = htApp.app_ui_settings;
                console.log("SAVING NEW JSON", this.ui_settings);

                this.uiUpdated.next({ ...this.ui_settings });
              });
            }
          }
        } else {
          if (!!htApp.app_ui_settings) {
            // this.ui_settings = htApp.app_ui_settings;
            Storage.set({
              key: UI_SETTINGS,
              value: JSON.stringify(htApp.app_ui_settings)
            }).then((_) => {
              this.ui_settings = htApp.app_ui_settings;

              this.uiUpdated.next({ ...this.ui_settings });
            });
          } else {
            // this.ui_settings = environment.appUISettings;
            this.ui_settings = environment.appUISettings;
            this.uiUpdated.next({ ...this.ui_settings });
          }
        }
        return this.ui_settings;
      });
  }

  setupUI(hotelSettings) {
    this.ui_settings = hotelSettings;
    this.uiUpdated.next({ ...this.ui_settings });
    Storage.get({ key: UI_SETTINGS }).then((old_ui_config) => {
      if (old_ui_config) {
        let old_config = JSON.parse(old_ui_config.value);
        if (old_config) {
          Storage.set({
            key: UI_SETTINGS,
            value: JSON.stringify(this.ui_settings)
          });
        } else {
          Storage.set({
            key: UI_SETTINGS,
            value: JSON.stringify(this.ui_settings)
          });
        }
      } else {
        Storage.set({
          key: UI_SETTINGS,
          value: JSON.stringify(this.ui_settings)
        });
      }
    });
  }

  getUISettings() {
    return this.ui_settings;
  }

  clearUISettings() {
    this.ui_settings = null;
    this.uiUpdated.next({ ...this.ui_settings });
    return Storage.set({
      key: UI_SETTINGS,
      value: null
    });
  }

  getStoredApplication() {
    return Storage.get({ key: "application" });
  }

  getApplication(): Observable<any> {
    return this.apiService
      .get(
        "applications/current",
        {
          // include: 'pages,pages.weekSchedule,pages.reservationForm,pages.space,pages.service'
        },
        null,
        false
      )
      .pipe(
        map((res) => {
          this.languageService.setAvailLangs(res["data"].langs);
          if (res["data"].langs.length === 1) {
            this.translateService.use(res["data"].langs[0]);
            Storage.set({ key: "lang", value: res["data"].langs[0] });
          }
          this.application = res["data"];

          this.appUpdated.next({ ...this.application });
          return res["data"];
        })
      );
  }

  setApplication(application) {
    // console.log("Setting application", application);
    return Storage.set({
      key: "application",
      value: JSON.stringify(application)
    });
  }

  async resetApplication() {
    await Storage.remove({ key: "application" });
  }
}
