import { Injectable } from "@angular/core";
import { map, tap, switchMap } from "rxjs/operators";
import { BehaviorSubject, from, Observable } from "rxjs";

import { Storage } from "@capacitor/storage";
import { ApiService } from "../api/api.service";
import { AnalyticsService } from "../analytics/analytics.service";

const TOKEN_KEY = "ht-token";
const HOTEL_ID_KEY = "ht-hotel_id";
const USER_KEY = "ht-user";
const BOOKING_KEY = "ht-booking";
const BOOKING_ID_KEY = "ht-booking-key";
@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  hotelChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  token: string = "";
  hotel_id: string = "";
  user = null;
  booking;
  booking_id;
  constructor(
    private api: ApiService,
    private analyticsSrvc: AnalyticsService
  ) {
    // this.isAuthenticated.next(false);
    // this.hotelChanged.next(null);

    this.loadToken();
    this.loadHotelId();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    const user = await Storage.get({ key: USER_KEY });
    const booking_id = await Storage.get({ key: BOOKING_ID_KEY });

    if (token && token.value && token.value !== "null") {
      this.token = token.value;
      if (user.value) this.user = JSON.parse(user.value);
      this.booking_id = booking_id.value;
      // console.log("set booking_id: ", this.booking_id, this.user, this.token);

      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async loadHotelId() {
    const hotel_id = await Storage.get({ key: HOTEL_ID_KEY });
    if (!!hotel_id && !!hotel_id.value && hotel_id.value !== "null") {
      // console.log("set hotel_id: ", hotel_id.value);
      this.hotel_id = hotel_id.value;
      this.hotelChanged.next(this.hotel_id);
    } else {
      this.hotel_id = null;
    }
  }

  login(loginData): Observable<any> {
    // console.log(credentials);
    return this.api.post("guests/login", loginData, null).pipe(
      map((data: any) => data.data),
      switchMap((res) => {
        // console.log("LOGIN RESULT", res);
        this.token = res.token;
        this.hotel_id = res.hotel_id;
        this.booking_id = res.booking_id;
        this.analyticsSrvc.setHotel(res.hotel_id);

        return from(
          Promise.all([
            Storage.set({ key: TOKEN_KEY, value: res.token }),
            Storage.set({ key: HOTEL_ID_KEY, value: res.hotel_id }),
            Storage.set({ key: USER_KEY, value: JSON.stringify(res.user) }),
            Storage.set({
              key: BOOKING_KEY,
              value: JSON.stringify(res.booking)
            }),
            Storage.set({
              key: BOOKING_ID_KEY,
              value: res.booking_id.toString()
            })
          ]).then(() => {
            this.loadToken();
            this.loadHotelId();
            // this.analyticsSrvc.setUser(this.booking_id);
          })
        );
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
      })
    );
  }

  logout(): Promise<[void, void, void, void]> {
    this.user = null;
    this.token = null;
    this.booking_id = null;
    this.isAuthenticated.next(false);
    return Promise.all([
      Storage.set({ key: TOKEN_KEY, value: null }),
      Storage.set({ key: HOTEL_ID_KEY, value: null }),
      Storage.set({ key: USER_KEY, value: null }),
      Storage.set({ key: BOOKING_ID_KEY, value: null })
    ]);
  }

  getToken() {
    return this.token;
  }

  getHotelId() {
    return this.hotel_id;
  }

  getUser() {
    return this.user;
  }

  getBooking() {
    return new Promise((resolve, reject) => {
      this.api
        .get("guests/booking", null, {
          headers: {
            Authorization: "Bearer " + this.getToken()
          }
        })
        .pipe(map((data: any) => data.data))
        .subscribe(
          (res) => {
            this.booking = res;
            Storage.set({ key: BOOKING_KEY, value: JSON.stringify(res) }).then(
              () => {
                resolve(res);
              }
            );
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getBookingId() {
    return this.booking_id;
  }

  setHotelId(hotel_id) {
    // console.log(hotel_id, "Hotel ID Set");
    this.hotel_id = hotel_id;
    this.analyticsSrvc.setHotel(hotel_id);

    return Storage.set({ key: HOTEL_ID_KEY, value: hotel_id }).then(() => {
      this.hotelChanged.next(hotel_id);
    });
  }

  setToken(token) {
    Storage.set({ key: TOKEN_KEY, value: token }).then((res) => {
      this.loadToken();
      this.loadHotelId();
    });
  }
  checkTokenValidity() {}
}
