import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  HttpBackend,
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CacheModule } from 'ionic-cache';
import { ApiService } from './ht-client-core/common/services/api/api.service';
import { HotelService } from './ht-client-core/common/services/hotel/hotel.service';
import { AuthenticationService } from './ht-client-core/common/services/authentication/authentication.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { EditorService } from './services/editor/editor.service';
import { UtilsService } from './ht-client-core/common/services/utils/utils.service';
import { HttpBlockInterceptor } from './interceptors/http-block.interceptor';
import { CacheInterceptor } from './interceptors/http-cache.interceptor';
import { CacheResolverService } from './services/editor-cache/editor-cache.service';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/core-i18n/' },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CacheModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [
    HttpClient,
    ApiService,
    HotelService,
    AuthenticationService,
    UtilsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SocialSharing,
    EditorService,
    CacheResolverService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBlockInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
